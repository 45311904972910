import React from 'react';
import { useLocation } from 'react-router';
import cn from 'classnames';
import _ from 'lodash';

import { CONFIG } from 'constants/index';
import { W_MANDATE_GET_PRIVATE_ASSETS } from 'api/routes';
import { offer as offerApi } from 'client/redux/api/offer';
import { OFFER_STATUS } from 'src/models/offer/interface';
import { useAppSelector, useTranslation, useAppDispatch, useResponsive, useAnalytics } from 'client/hooks';
import { setFooterState } from 'client/redux/ui';
import { selectUserId, selectUserRole } from 'client/redux/user/selectors';
import { Button, Icon, Loader } from 'client/components/common';

import { OptionsSection } from './OptionsSection';
import { InputSection } from './InputSection';
import { SuccessMessage } from './SuccessMessage';

import { prettifyValue, linkTemplate } from './helpers';

import css from './OffersPopup.module.scss';

interface Props {
  className?: string;
  companyId: string;
  mandateId: string;
  companyName: string;
  investmentTermsLink: string;
  investmentMinAmount?: number;
  onClose: () => unknown;
}

const AMOUNT_OPTIONS = [
  {
    amount: '25000',
    label: '$25,000',
  },
  {
    amount: '50000',
    label: '$50,000',
  },
  {
    amount: '100000',
    label: '$100,000',
  },
  {
    amount: '200000',
    label: '$200,000',
  },
  {
    amount: '500000',
    label: '$500,000',
  },
];

const FEE_PERSENT = 12;
const MIN_AMOUNT = 25000;

export const OffersPopup: React.FC<Props> = ({
  onClose,
  companyName,
  companyId,
  mandateId,
  investmentTermsLink,
  investmentMinAmount = MIN_AMOUNT,
}) => {
  const [isInputShowed, setIsInputShowed] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [fee, setFee] = React.useState(0);
  const [isMobile] = useResponsive(['MOBILE']);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const analytics = useAnalytics();

  const totalRef = React.createRef<HTMLSpanElement>();
  const [feeValueWidth, setFeeValueWidth] = React.useState(0);
  const [isFeeIncluded, setIsFeeIncluded] = React.useState(true);
  const [isTermsChecked, setIsTermsChecked] = React.useState(false);
  const [isTermsCheckedError, setIsTermsCheckedError] = React.useState(false);

  const userId = useAppSelector(selectUserId);
  const userRole = useAppSelector(selectUserRole);
  const [createOffer, createState] = offerApi.endpoints.createOffer.useMutation();
  const { data, isFetching } = offerApi.endpoints.getOffer.useQuery({ companyId });
  // return status if order exist, undefined - no order info, so it's a new order
  const offerInfo = React.useMemo(() => (data?.success ? data.data : null), [data]);

  const { translate: _t } = useTranslation('popups');
  const checkboxLabel = _t('offersPopup.checkbox').replace(
    '{terms}',
    linkTemplate(
      `${CONFIG.domainUrl}${W_MANDATE_GET_PRIVATE_ASSETS}?fileName=${investmentTermsLink}`,
      _t('offersPopup.checkboxTermsLabel'),
    ),
  );

  React.useEffect(() => {
    if (totalRef !== null) {
      const w = totalRef.current?.getBoundingClientRect().width;
      if (w) {
        setFeeValueWidth(w);
      }
    }

    analytics.gtag.event('page_view', {
      page_title: `Mandate - ${companyName} - place an offer`,
      page_location: `${CONFIG.domainUrl}${location.pathname}`,
    });
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      dispatch(setFooterState('hidden'));
    }
  }, [isMobile]);

  React.useEffect(() => {
    // calculate fee
    if (amount) {
      const fee = isFeeIncluded ? (amount / (100 + FEE_PERSENT)) * FEE_PERSENT : (amount / 100) * FEE_PERSENT;
      setFee(fee);
    } else {
      setFee(0);
    }
  }, [amount, isFeeIncluded]);

  React.useEffect(() => {
    // set same height to input section, avoid jumps
    if (totalRef !== null) {
      const w = totalRef.current?.getBoundingClientRect().width;
      if (w) {
        setFeeValueWidth(w);
      }
    }
  }, [fee, totalRef]);

  const onSubmit = async () => {
    if (isTermsChecked) {
      analytics.gtag.event('Submit an offer', {
        mandateId,
        InvestorID: userId,
        Amount: `${amount}`,
        FeesIncluded: isFeeIncluded ? 'yes' : 'no',
      });

      await createOffer({
        userId,
        companyId,
        value: amount,
        isFeesIncluded: isFeeIncluded,
        status: OFFER_STATUS.NEW,
      });
    } else {
      setIsTermsCheckedError(true);
    }
  };

  const onOtherOptionClick = () => {
    setIsInputShowed(true);
    setAmount(0);
    setFee(0);
  };

  const onBackClick = () => {
    setIsInputShowed(false);
    setAmount(0);
    setFee(0);
  };

  const calculateTotal = () => {
    return isFeeIncluded ? prettifyValue(`${amount}`) : prettifyValue(`${amount + fee}`);
  };

  const onCloseClick = () => {
    dispatch(setFooterState('visible'));
    onClose();
  };

  console.info(_.filter(AMOUNT_OPTIONS, (opt) => Number(opt.amount) >= MIN_AMOUNT));

  const renderContent = () => {
    return (
      <>
        {(offerInfo && offerInfo?.status) || createState.isSuccess ? (
          <SuccessMessage onClose={onClose} userRole={userRole} />
        ) : (
          <div className={css.offerSection}>
            <h6 className={css.title}>
              {!isMobile && <div>{_t('offersPopup.title')}</div>}
              <div className={css.companyName}>{companyName}</div>
            </h6>

            {!isMobile && (
              <button className={css.closeBtn} onClick={onCloseClick}>
                <Icon type="close" />
              </button>
            )}

            <div className={css.investAmount}>
              {!isInputShowed ? (
                <OptionsSection
                  options={_.filter(AMOUNT_OPTIONS, (opt) => Number(opt.amount) >= investmentMinAmount)}
                  onInputSectionOpen={onOtherOptionClick}
                  getAmount={(am: number) => setAmount(am)}
                />
              ) : (
                <InputSection
                  minAmount={investmentMinAmount}
                  onBackClick={onBackClick}
                  getAmount={(am: number) => setAmount(am)}
                />
              )}
            </div>

            <div className={css.feeSwitcher}>
              <span className={css.labelText}>{_t('offersPopup.switcherLabel')}</span>
              <div className={css.switchWrap}>
                <input
                  type="checkbox"
                  id="switch"
                  checked={isFeeIncluded}
                  onChange={(e) => setIsFeeIncluded(e.target.checked)}
                />
                <label htmlFor="switch" />
              </div>
            </div>

            <div className={css.resultAmount}>
              <div className={css.row}>
                <span className={css.rowTitle}>{_t('offersPopup.fees')}</span>
                <span className={css.rowValue} style={{ width: `${feeValueWidth}px` }}>
                  {prettifyValue(fee)}
                </span>
                {/* <span className={css.rowValue}>{prettifyValue(fee)}</span> */}
              </div>
              <div className={css.row}>
                <span className={css.rowTitle}>{_t('offersPopup.total')}</span>
                <span className={cn(css.rowValue, css.totalSum)} ref={totalRef}>
                  {calculateTotal()}
                </span>
              </div>
            </div>

            <label className={css.checkbox}>
              <input type="checkbox" onChange={(e) => setIsTermsChecked(e.target.checked)} />
              <span className={css.checkMark}></span>
              <span className={css.labelText} dangerouslySetInnerHTML={{ __html: checkboxLabel }}></span>
              {isTermsCheckedError && !isTermsChecked && (
                <span className={css.error}>{_t('offersPopup.checkboxError')}</span>
              )}
            </label>

            {createState.isLoading ? (
              <Loader position="relative" className={css.loader} />
            ) : (
              <Button
                className={css.submitButton}
                onClick={onSubmit}
                disabled={!amount || Number(amount) < Number(investmentMinAmount)}
              >
                {_t('offersPopup.submitBtn')}
              </Button>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={css.popup}>
      <div className={css.popupOverlay} onClick={onCloseClick} />
      {isMobile && <button className={css.closeBtn} onClick={onCloseClick} />}
      {isFetching && <Loader position="absolute" className={css.popupLoader} />}
      {!isFetching && <div className={css.popupContent}>{renderContent()}</div>}
    </div>
  );
};
