import React from 'react';
import { useResponsive, useTranslation } from 'client/hooks';

import { HelmetComponent } from 'client/components/common';
import { ControlPanel, Header } from 'client/components/common/mobile';
import { Icon } from 'client/components/common';

import Slider from './Slider';
import Form from './Form';
import Thanks from './Thanks';

import coverMob from 'client/assets/contact-us/cover-mob.jpg';
import coverDesk from 'client/assets/contact-us/cover-desk.jpg';

import css from './ContactUs.module.scss';

const contactPhoneNumber = '+972 3 695 0666';
const contactPhoneNumber2 = '+972 58 785 6689';

const ContactUs = () => {
  const [isMobile] = useResponsive(['MOBILE']);
  const [step, setStep] = React.useState<'form' | 'thanks'>('form');
  const { translate } = useTranslation();

  const onSucces = () => {
    setStep('thanks');
  };

  return (
    <>
      <HelmetComponent
        title="Contact us - Catalyst Investors' Club"
        description="Join Catalyst Investors' Club to invest alongside reputable lead investors. Find out more about the club."
        ogTitle="Contact us - Catalyst Investors' Club"
        ogDescription="Join Catalyst Investors' Club to invest alongside reputable lead investors. Find out more about the club."
      ></HelmetComponent>
      <div className={css.pageWrapper}>
        {isMobile ? <Header className={css.pageHeader} /> : null}
        {isMobile ? <ControlPanel className={css.controlPanel} /> : null}
        <div className={css.content}>
          <div className={css.cover} style={{ backgroundImage: `url(${isMobile ? coverMob : coverDesk})` }}></div>
          <div className={css.contentWrapper}>
            <div className={css.colLeft}>
              <div className={css.text}>
                <div className={css.title}>{translate('contactUsPage.title')}</div>
                <div className={css.subTitle}>{translate('contactUsPage.subtitle')}</div>
              </div>
              <div className={css.formWrapper}>
                <div className={css.gradientBlock}></div>
                {step === 'form' ? (
                  <>
                    <div className={css.title}>{translate('contactUsPage.leftCol.getInTouchTitle')}</div>
                    <Form onSucces={onSucces} />
                  </>
                ) : (
                  <Thanks />
                )}
              </div>
            </div>
            <div className={css.colRight}>
              <Slider />
              <div className={css.additionalInfo}>
                <div className={css.gradientBlock}></div>
                <div className={css.wrapper}>
                  <div className={css.phone}>
                    <div className={css.title}>{translate('contactUsPage.rightCol.callUsTitle')}</div>
                    <div className={css.text}>{translate('contactUsPage.rightCol.callUsText')}</div>
                    <div className={css.contactPhone}>
                      <a href="tel:+97236950666">
                        <Icon type="phone" className={css.iconPhone} />
                        <span>{contactPhoneNumber}</span>
                      </a>
                      <a href="tel:+972587856689">
                        <Icon type="phone" className={css.iconPhone} />
                        <span>{contactPhoneNumber2}</span>
                      </a>
                    </div>
                  </div>
                  <div className={css.address}>
                    <div className={css.title}>{translate('contactUsPage.rightCol.addressTitle')}</div>
                    <div className={css.text}>
                      <Icon type="location" className={css.iconLocation} />
                      <span>{translate('contactUsPage.rightCol.addressText')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
