import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router';
import cn from 'classnames';
import _ from 'lodash';

import { Result as Mandate } from 'api/mandate/website/get/params';
import { USER_ROLE } from 'models/user/interface';
import { INVESTMENT_STATUS } from 'models/mandate/interface';
import { useMandate, useTranslation, useResponsive, useOnScreen, useAnalytics } from 'client/hooks';
import { numberToCurrency } from 'client/utils/number-to-currency';
import { vw_d } from 'client/utils/vw';
import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import {
  Button,
  CompanyInfo,
  ContentWrap,
  DonutChart,
  ExpandableBlock,
  OpacityTransitionComponent,
  PageNavigation,
  RestrictedBlock,
  HelmetComponent,
  Icon,
  DealTermsExpandable,
} from 'client/components/common';

import { ControlPanel, Header } from 'client/components/common/mobile';
import { useAppDispatch, useAppSelector } from 'client/hooks/redux';
import { setPopup, setFooterState } from 'client/redux/ui';
import { W_MANDATE_GET_PRIVATE_ASSETS } from 'api/routes';
import { selectUserRole, selectAuth, selectUserId, selectUserGAStatus } from 'client/redux/user/selectors';
import { statistics as mandateStatisticsApi } from 'client/redux/api/statistics';

import documentIconImg from 'client/assets/icons/download.svg';
import { CONFIG, MANDATE_RAISED_FIELD_OPTIONS } from 'constants/index';

import { Skeleton } from './Skeleton';
import { PageError } from './PageError';
import { Hero } from './Hero';
import { OffersPopup } from './OffersPopup';
import { TargetInfoMobile } from './TargetInfoMobile';
import { InvestorDetails, InvestorData } from './InvestorDetails';
import { VideoPlaylist } from './VideoPlaylist';
import { mandate as dummyData } from './dummy-data';
import bgImgUrl from './assets/bg.png';
import css from './Mandate.module.scss';

type Props = {
  className?: string;
};

/**
 * http://localhost:8000/mandate/65a3f71806acb03915be58df
 */
function MandatePage(props: Props) {
  const { className = '' } = props;
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const analytics = useAnalytics();
  const userId = useAppSelector(selectUserId);
  const userGAStatus = useAppSelector(selectUserGAStatus);

  const [currentInvestorDetails, setCurrentInvestorDetails] = React.useState<InvestorData | null>(null);
  const { id: mandateId } = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const { mandate, seoData, isFetching } = useMandate(mandateId);
  const [isOffersPopupOpen, setIsOffersPopupOpen] = React.useState(false);
  const [isMobile] = useResponsive(['MOBILE']);
  const { isLoggedIn, isCompleted } = useAppSelector(selectAuth);
  const t = useTranslation().createPrefixedTranslationFunc('mandate');
  const [updateMandateStats] = mandateStatisticsApi.endpoints.updateStats.useMutation();

  const contentRef = React.useRef<HTMLDivElement>(null);
  const isContentViewable = useOnScreen(contentRef);

  const navigation = [
    { label: t('about'), path: '#about' },
    { label: t('founders'), path: '#founders' },
    { label: t('keyInfo'), path: '#key-info' },
    { label: t('dealTerms'), path: '#deal-terms' },
    { label: t('investors'), path: '#investors' },
    { label: t('videos'), path: '#videos' },
    {
      label: t('documents'),
      path: '#documents',
      icon: <Icon type="docs" className={css.docsIcon} />,
      highlighted: true,
    },
  ].filter((nav) =>
    mandate?.investmentStatus === INVESTMENT_STATUS.OVER_SUBSCRIBED
      ? !['#videos', '#documents'].includes(nav.path)
      : nav,
  ) as {
    label: string;
    path: string;
    icon?: React.ReactNode;
    highlighted?: boolean;
  }[];

  React.useEffect(() => {
    if (userGAStatus) {
      if (mandate && isLoggedIn && isCompleted && !isFetching) {
        analytics.gtag.event('page_view', {
          page_title: `Mandate - ${mandate.name}`,
          page_location: `${CONFIG.domainUrl}${location.pathname}`,
        });
      }
    }
  }, [isLoggedIn, isCompleted, mandate, isFetching, userGAStatus]);

  React.useEffect(() => {
    if (userRole !== USER_ROLE.VIEWER && mandate?.companyId) {
      updateMandateStats({ companyId: mandate.companyId });
    }
  }, [userRole, mandate]);

  React.useEffect(() => {
    if (isLoggedIn && userRole === 'VIEWER') {
      _.defer(() => {
        dispatch(setPopup({ type: 'join-as-investor', data: { link: location.pathname, isExternalLink: false } }));
        history.push(MAIN_PAGE_ROUTE);
      }, 0);
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    const heroEl = document.querySelector(`.${css.hero}`);
    const observer = new IntersectionObserver(
      ([entry]) => document.querySelector(`.${css.bg}`)?.classList.toggle(css.sticky, !entry.isIntersecting),
      { threshold: 0 },
    );

    if (heroEl) {
      observer.observe(heroEl);
    }

    return () => {
      if (heroEl) {
        observer.unobserve(heroEl);
      }
    };
  }, []);

  React.useEffect(() => {
    if (currentInvestorDetails) {
      dispatch(setFooterState('hidden'));
    } else {
      dispatch(setFooterState('visible'));
    }
  }, [currentInvestorDetails, dispatch]);

  const onInvestorBtnClick = (investor: InvestorData) => {
    setCurrentInvestorDetails(investor);
  };

  const onPlaceAnOfferBtnClick = () => {
    if (mandate) {
      analytics.gtag.event(`Place an offer click`, { mandateID: `${mandate._id}` });
    }

    if (['INVESTOR_LEVEL_1', 'VIEWER'].includes(userRole)) {
      dispatch(setPopup({ type: 'accreditation-questionnaire' }));
    } else {
      setIsOffersPopupOpen(true);
    }
  };

  const renderContent = () => {
    return mandate && isLoggedIn ? (
      <>
        {!isMobile && <div className={css.bg} style={{ backgroundImage: `url(${bgImgUrl})` }} />}
        <Hero
          className={css.hero}
          mandate={mandate}
          onOfferClick={onPlaceAnOfferBtnClick}
          onInvestorClick={onInvestorBtnClick}
        />
        {!isMobile && (
          <PageNavigation
            stickyMode
            anchorMode
            navigation={navigation}
            logo={
              <>
                <img src={mandate?.logo} />
                <p>{mandate.name}</p>
              </>
            }
          />
        )}
        <div className={css.contentWrap} ref={contentRef}>
          <ContentWrap>
            <div className={css.content}>
              {renderMainColumn(mandate)}
              {!isMobile && renderAsideColumn(mandate)}
            </div>
          </ContentWrap>
        </div>
        <InvestorDetails data={currentInvestorDetails} onClose={() => setCurrentInvestorDetails(null)} />
        {mandate.investmentStatus !== INVESTMENT_STATUS.OVER_SUBSCRIBED && (
          <>
            <OpacityTransitionComponent visible={isOffersPopupOpen}>
              <OffersPopup
                onClose={() => setIsOffersPopupOpen(false)}
                investmentTermsLink={mandate.investmentTerms as string}
                companyName={mandate.name as string}
                companyId={mandate.companyId as string}
                mandateId={mandate._id as string}
                investmentMinAmount={mandate.investmentMinAmount}
              />
            </OpacityTransitionComponent>
            {isMobile && ['INVESTOR_LEVEL_2', 'INVESTOR_LEVEL_3'].includes(userRole) ? (
              <OpacityTransitionComponent visible={isContentViewable}>
                <TargetInfoMobile mandate={mandate} onOfferClick={() => setIsOffersPopupOpen(true)} />
              </OpacityTransitionComponent>
            ) : null}
          </>
        )}
      </>
    ) : (
      <PageError message="Unable to get page data" />
    );
  };

  const renderMainColumn = (mandate: NonNullable<Mandate['mandate']>) => {
    return (
      <div className={css.mainColumn}>
        <section id="about" className={css.section}>
          <CompanyInfo
            withLogo={false}
            withCompanySize
            companyInfo={{ ...mandate, description: mandate.about, name: `${t('about')} ${mandate.name}` }}
          />
        </section>

        <section id="founders" className={css.section}>
          <p className={css.sectionTitle}>{t('founders')}</p>
          {mandate?.team?.map((member) => (
            <div className={css.teamMember} key={`${member.name}_${member.role}`}>
              <div className={css.teamMemberPhotoAndName}>
                <div className={css.teamMemberPhoto} style={{ backgroundImage: `url(${member.avatar})` }} />
                <div className={css.teamMemberInfo}>
                  <div className={css.teamMemberName}>{member.name}</div>
                  <div className={css.teamMemberRole}>{member.role}</div>
                </div>
              </div>
              {member.description && (
                <div className={css.teamMemberDescription}>
                  <ExpandableBlock height={isMobile ? 91 : vw_d(74)} content={member.description} />
                </div>
              )}
            </div>
          ))}
        </section>

        {!isMobile && mandate?.investmentStatus !== INVESTMENT_STATUS.OVER_SUBSCRIBED && (
          <section id="videos" className={cn(css.section, css.videos)}>
            <p className={css.sectionTitle}>{t('videos')}</p>
            {mandate?.videoGallery && <VideoPlaylist videos={mandate?.videoGallery} />}
          </section>
        )}

        <section id="key-info" className={css.section}>
          <p className={css.sectionTitle}>{t('keyInfo')}</p>
          <RestrictedBlock
            unlockPopUp
            fields={['keyInformation']}
            fallback={
              <div className={css.keyInfoContent} dangerouslySetInnerHTML={{ __html: dummyData.keyInformation }} />
            }
            onUnlockBtnClick={() => dispatch(setPopup({ type: 'accreditation-questionnaire' }))}
          >
            <div className={css.keyInfoContent} dangerouslySetInnerHTML={{ __html: mandate.keyInformation ?? '' }} />
          </RestrictedBlock>
        </section>

        <section id="deal-terms" className={css.section}>
          <p className={css.sectionTitle}>{t('dealTerms')}</p>
          <RestrictedBlock
            unlockPopUp
            fields={['dealTerms']}
            fallback={
              <div className={css.dealTermsContent} dangerouslySetInnerHTML={{ __html: dummyData.dealTerms }} />
            }
            onUnlockBtnClick={() => dispatch(setPopup({ type: 'accreditation-questionnaire' }))}
          >
            <DealTermsExpandable info={mandate.dealTerms ?? ''} className={css.dealTermsContent} />
          </RestrictedBlock>
        </section>

        <section id="investors" className={css.section}>
          <p className={css.sectionTitle}>{t('investors')}</p>
          <div className={css.investorsContent}>
            {mandate?.leadInvestorInfo?.map((investor, index) => (
              <button
                className={css.investor}
                key={`${investor.name}_${index}`}
                onClick={() => onInvestorBtnClick(investor)}
              >
                <div className={css.investorLogo} style={{ backgroundImage: `url(${investor.logo})` }} />
                <div className={css.investorDetails}>
                  <div className={css.investorName}>{investor.name}</div>
                  <div className={css.investorType}>{t('group')}</div>
                </div>
              </button>
            ))}
          </div>
        </section>

        {mandate?.investmentStatus !== INVESTMENT_STATUS.OVER_SUBSCRIBED && (
          <section id="documents" className={css.section}>
            <div className={css.borderedWrap}>
              <p className={css.sectionTitle}>{t('documents')}</p>
              {/* <p className={css.documentsSubTitle}>Legal Dataroom</p> */}
              <RestrictedBlock
                unlockPopUp
                fields={['documents']}
                fallback={
                  <div className={css.documentsContent}>
                    {dummyData.documents?.map((document, index) => (
                      <a className={css.documentLink} key={`${document.title}_${index}`}>
                        <img className={css.documentLinkIcon} src={documentIconImg} />
                        <span className={css.documentLinkName}>{document.title}</span>
                      </a>
                    ))}
                  </div>
                }
                onUnlockBtnClick={() => dispatch(setPopup({ type: 'accreditation-questionnaire' }))}
              >
                {mandate.documents?.map((document, index) => (
                  <a
                    className={css.documentLink}
                    href={`${CONFIG.domainUrl}${W_MANDATE_GET_PRIVATE_ASSETS}?fileName=${document.file}`}
                    target="_blank"
                    key={`${document.title}_${index}`}
                    rel="noreferrer"
                    onClick={() =>
                      analytics.gtag.event('view_document', {
                        mandateId: mandate._id ?? 'N/A',
                        Name: document.title,
                        InvestorID: userId,
                      })
                    }
                  >
                    <Icon type="docs" className={css.documentLinkIcon} />
                    <span className={css.documentLinkName}>{document.title}</span>
                  </a>
                ))}
              </RestrictedBlock>
            </div>
          </section>
        )}
      </div>
    );
  };

  const renderAsideColumn = (mandate: NonNullable<Mandate['mandate']>) => {
    const renderProgressBlock = (props: {
      target: number | string;
      raised: number | string;
      raisedFieldLabel?: string;
      prevRoundRaised: number | string;
      valuation: number | string;
      progress: number;
      seriesFunding: string;
      isProgressVisible: boolean;
    }) => {
      const { isProgressVisible, raisedFieldLabel } = props;
      const raisedFieldTitle =
        MANDATE_RAISED_FIELD_OPTIONS.find((m) => m.value === raisedFieldLabel)?.label ||
        MANDATE_RAISED_FIELD_OPTIONS[0].label;

      return (
        <>
          {isProgressVisible && (
            <DonutChart className={css.progressChart} values={[{ color: '#0757d3', progress: props.progress }]} />
          )}
          <div className={css.progressInfo}>
            <div className={css.progressInfoSection}>
              <div className={css.target}>
                {typeof props.target === 'number'
                  ? `${t('investmentTarget')} ${numberToCurrency(props.target)}`
                  : props.target}
              </div>
              <div className={css.raised}>
                {typeof props.raised === 'number'
                  ? `${raisedFieldTitle} ${numberToCurrency(props.raised)}`
                  : props.raised}
              </div>
            </div>
            <div className={css.progressInfoSection}>
              <div className={css.currentRound}>
                {props.seriesFunding}
                {props.seriesFunding && props.valuation && ' | '}
                {typeof props.valuation === 'number'
                  ? `${numberToCurrency(props.valuation)} ${t('investmentValuation')}`
                  : props.valuation}
              </div>
              {typeof props.prevRoundRaised === 'number' && Boolean(props.prevRoundRaised) && (
                <div className={css.previousRounds}>
                  {typeof props.prevRoundRaised === 'number'
                    ? `${t('investmentPrevRound')} ${numberToCurrency(props.prevRoundRaised)}`
                    : props.prevRoundRaised}
                </div>
              )}
            </div>
          </div>
        </>
      );
    };

    return (
      <aside className={css.asideColumn}>
        {mandate.investmentStatus !== INVESTMENT_STATUS.OVER_SUBSCRIBED && (
          <div className={cn(css.asideBlock, css.progress)}>
            <div className={css.asideBlockSection}>
              <RestrictedBlock
                className={css.progressWrap}
                fields={[
                  'progressBarPercentage',
                  'raised',
                  'seriesFunding',
                  'recruitmentTotalAmount',
                  'valuation',
                  'prevRounds',
                  'isProgressBarVisible',
                ]}
                fallback={renderProgressBlock({
                  progress: (dummyData.progressBarPercentage ?? 0) / 100,
                  raised: dummyData.raised,
                  raisedFieldLabel: mandate.raisedFieldLabel,
                  seriesFunding: dummyData.seriesFunding ?? '',
                  target: dummyData.recruitmentTotalAmount,
                  valuation: dummyData.valuation,
                  prevRoundRaised: dummyData.prevRounds,
                  isProgressVisible: !!dummyData.isProgressBarVisible,
                })}
                onUnlockBtnClick={() => dispatch(setPopup({ type: 'accreditation-questionnaire' }))}
              >
                {renderProgressBlock({
                  progress: (mandate.progressBarPercentage ?? 0) / 100,
                  raised: +(mandate.raised ?? 0),
                  raisedFieldLabel: mandate.raisedFieldLabel,
                  seriesFunding: mandate.seriesFunding ?? '',
                  target: +(mandate.recruitmentTotalAmount ?? 0),
                  valuation: mandate.valuation ?? 0,
                  prevRoundRaised: +(mandate.prevRounds ?? 0),
                  isProgressVisible: !!mandate.isProgressBarVisible,
                })}
              </RestrictedBlock>

              <div className={css.actionsWrap}>
                {mandate.termsOfDealLink && (
                  <a href={mandate.termsOfDealLink} target="_blank" rel="noreferrer" className={css.termsOfDeal}>
                    <p>{t('termsOfDeal')}</p>
                    <div className={css.termsOfDealIcon}></div>
                  </a>
                )}
                {mandate.termsOfDeal && !mandate.termsOfDealLink && (
                  <div className={css.termsOfDeal}>
                    <p>{t('termsOfDeal')}</p>
                    <div className={css.termsOfDealIcon}>
                      <div className={css.termsOfDealTooltip}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: mandate.termsOfDeal,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className={css.btnWrap}>
                  <Button className={css.placeAnOfferBtn} onClick={onPlaceAnOfferBtnClick}>
                    Place an offer
                  </Button>
                </div>
              </div>
            </div>
            {(mandate.leadInvestorInfo?.length ?? 0) > 0 && (
              <div className={css.asideBlockSection}>
                <div className={css.asideBlockSectionTitleWrap}>
                  <p className={css.asideBlockSectionTitle}>{t('joinTheLeadInvestors')}</p>
                  <a className={css.asideBlockSectionTitleBtn} href="#investors">
                    {t('viewAll')}
                  </a>
                </div>
                {mandate?.leadInvestorInfo?.map((investor, index) => {
                  if (index > 1) return null;
                  return (
                    <button
                      key={index}
                      className={css.leadInvestor}
                      onClick={() => setCurrentInvestorDetails(investor)}
                    >
                      <div className={css.leadInvestorLogo} style={{ backgroundImage: `url(${investor.logo})` }} />
                      <div className={css.leadInvestorName}>{investor.name}</div>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </aside>
    );
  };

  return (
    <div className={cn(css.mandate, className)} id="mandate">
      <HelmetComponent
        title={`Invest in ${seoData?.name} - Catalyst Investors' Club`}
        description={`${seoData?.description}`}
        ogTitle={`Invest in ${seoData?.name} - Catalyst Investors' Club`}
        ogDescription={`${seoData?.description}`}
        preventToSearch
      />
      {isMobile ? <Header className={css.pageHeader} /> : null}
      {isMobile ? <ControlPanel className={css.controlPanel} /> : null}
      {isFetching || !isCompleted ? <Skeleton /> : renderContent()}
    </div>
  );
}

export default MandatePage;
