import * as yup from 'yup';

import * as routes from 'api/routes';
import { IModel } from 'models/company/interface';
import { IModel as MainPageModel, SlidersKeys } from 'models/main_page/interface';
import { INVESTMENT_STATUS } from 'models/mandate/interface';
import { VALIDATION_ERROR } from 'endpoints/index';

type MainPageKeys = keyof Omit<MainPageModel, '_id' | 'isComingSoonVisible'>;
export type MainPageWebsiteSliderItem = Pick<
  IModel,
  'name' | 'mainImage' | 'decorImage' | 'rating' | 'avatarVideo' | 'subTitle' | 'shortDescription' | 'team'
> & {
  _id: string;
  mandateInfo: {
    id: string;
    isVisible: boolean;
    investmentStatus: keyof typeof INVESTMENT_STATUS;
  } | null;
  createdAt: string;
};

export type Params = { type?: Array<SlidersKeys> };
export type Result = Partial<Record<MainPageKeys, Array<MainPageWebsiteSliderItem>> & { isComingSoonVisible: boolean }>;

export const paramsSchema: yup.SchemaOf<Params> = yup.object().shape({
  type: yup
    .array()
    .of(
      yup
        .mixed()
        .oneOf<SlidersKeys>(
          ['heroSlider', 'featuredSlider', 'investNowSlider', 'topWatchedSlider', 'comingSoonSlider'],
          VALIDATION_ERROR.INVALID_FORMAT,
        ),
    )
    .optional(),
});

export const ROUTE = routes.MAIN_PAGE_WEBSITE_GET;
